import styled from '@emotion/styled';
import { mdms } from 'api';
import { useState, useEffect, useCallback } from 'react';

import { toast } from '../components/PageBuilder/helpers/toast';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
`;

const ActionButton = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  height: 38px;
  margin: 0px 16px;
  padding: 9px 16px;
  text-decoration: underline;
  transition: transform 0.1s ease 0s;
  user-select: none;

  &:hover {
    border: 1px solid #000;
    text-decoration: none;
  }
`;

export default function useUserDraftPb({
  language,
  contentId,
  current_version_id,
  site,
  userDraft,
  getSnapshot = () => {},
  saveStatus = { saving: false, broken: false, publishing: false },
  restoreUserDraft = data => null,
  onSaveDraft = callback => null,
}) {
  const [timerId, setTimerId] = useState(null);
  const [restoredOnLoad, setRestoredOnLoad] = useState(false);
  const [localUserDraft, setLocalUserDraft] = useState(userDraft);

  const saveOnServer = useCallback(() => {
    if (!(saveStatus.broken || saveStatus.saving || saveStatus.publishing)) {
      mdms
        .secure_post(`/api/v2/cms/sites/${site}/user_content_drafts/upsert`, {
          language_id: language,
          cms_content_uuid: contentId,
          ...getSnapshot(),
        })
        .then(response => {
          if (response?.ok) {
            response.json().then(newUserDraft => {
              setLocalUserDraft(newUserDraft);
            });
          }
        });
    }
  }, [saveStatus, language, site, contentId, getSnapshot]);

  const clearStorage = useCallback(
    callbackAfterDelete => {
      clearTimeout(timerId);
      if (
        !(saveStatus.broken || saveStatus.saving || saveStatus.publishing) &&
        localUserDraft?.id
      ) {
        mdms
          .secure_delete(
            `/api/v2/cms/sites/${site}/user_content_drafts/${localUserDraft.id}`
          )
          .then(response => {
            if (response.ok) {
              const draftToastId = localStorage.getItem(
                `draftToastId/${contentId}`
              );
              if (draftToastId) {
                localStorage.removeItem(`draftToastId/${contentId}`);
                toast.dismiss(draftToastId);
              }
              setLocalUserDraft(null);
            }
          })
          .finally(() => {
            typeof callbackAfterDelete === 'function' && callbackAfterDelete();
          });
      }
    },
    [saveStatus, site, localUserDraft]
  );

  useEffect(() => {
    const preventAutosaveRestore = localStorage.getItem(
      'preventAutosaveRestore'
    );
    if (localUserDraft && !preventAutosaveRestore) {
      const cached_version_id = localUserDraft.current_version_id;
      if (cached_version_id === current_version_id && !restoredOnLoad) {
        restoreUserDraft(localUserDraft);
        setRestoredOnLoad(true);
        const toastId = toast.success(
          <div>
            <p>
              Your latest unsaved changes have been applied. Please{' '}
              <em>save draft</em> again to ensure changes; or <em>reset</em> to
              revert to latest saved draft.
            </p>
            <Wrapper>
              <ActionButton
                onClick={() => {
                  onSaveDraft(clearStorage);
                }}
              >
                SAVE DRAFT
              </ActionButton>
              <ActionButton
                onClick={() => {
                  clearStorage(() => window.location.reload());
                }}
              >
                RESET
              </ActionButton>
            </Wrapper>
          </div>,
          { autoClose: false }
        );
        localStorage.setItem(`draftToastId/${contentId}`, toastId);
      } else {
        clearStorage();
        toast.error(
          <div>
            Your latest unsaved changes have not been applied. Current version
            is the most up to date.
          </div>,
          { autoClose: true }
        );
      }
    }
    localStorage.removeItem('preventAutosaveRestore');
  }, [userDraft]);

  const onChangeSave = useCallback(() => {
    clearTimeout(timerId); // Clear any existing timer
    const newTimerId = setTimeout(() => {
      saveOnServer();
    }, 5000); // Set a new timer for 5 seconds

    setTimerId(newTimerId);
  }, [timerId, saveOnServer]);

  return {
    clearStorage,
    onChangeSave,
  };
}

export const useTabDetection = () => {
  useEffect(() => {
    // Check if the page is already open in another tab
    const pageId = window.location.href;
    const isTabOpen = localStorage.getItem(pageId);
    if (!isTabOpen) {
      // If not, set the flag in localStorage
      localStorage.setItem(pageId, true);
    } else {
      // If flag in localStorage, run action to warn user
      toast.warn(
        'Another instance of this page may be open, please close any duplicates',
        { autoClose: true }
      );
    }

    const handleStorageChange = event => {
      if (event.key === pageId) {
        // Page was closed in another tab, flag is reset by opened page
        localStorage.setItem(pageId, true);
      }
    };

    const handleWindowClose = () => {
      localStorage.removeItem(pageId);
    };
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('beforeunload', handleWindowClose);
    return () => {
      // Clean up the event listener and remove the flag when the component unmounts
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('beforeunload', handleWindowClose);
      localStorage.removeItem(pageId);
    };
  }, []);
};
